import { PageSizes, PDFDocument, StandardFonts } from 'pdf-lib'
import FORMATTER from '@/mixins/formatter'
import PDF from '@/mixins/pdf'
import FormatDate from 'moment'

export default {

  mixins: [PDF, FORMATTER],

  data () {
    const SIGNATURE_PATH_NAME = process.env?.VUE_APP_BIR_FORM_PAYOR_SIGNATURE_NAME || ''
    // const ASSET_VERSION = Math.round(Math.floor(Math.random() * 100))

    return {
      PDF_BIR_2307: {
        urls: {
          bir: `${window.location.origin}/2307-Jan-2018-ENCS-v3.pdf`,
          signature: `${window.location.origin}/img/signatures/${SIGNATURE_PATH_NAME}`
        },
        width: PageSizes.Folio[0],
        height: PageSizes.Folio[1],
        fontSize: 8,
        font: null,
        dates: {
          '1st-Q': {
            from: date => FormatDate(date).format('0101YYYY'),
            to: date => FormatDate(date).format('0331YYYY')
          },
          '2nd-Q': {
            from: date => FormatDate(date).format('0401YYYY'),
            to: date => FormatDate(date).format('0630YYYY')
          },
          '3rd-Q': {
            from: date => FormatDate(date).format('0701YYYY'),
            to: date => FormatDate(date).format('0930YYYY')
          },
          '4th-Q': {
            from: date => FormatDate(date).format('1001YYYY'),
            to: date => FormatDate(date).format('1231YYYY')
          }
        },
        incomePayments: {
          rows: [
            375, 389, 404, 418, 431, 445, 460, 473, 487, 500, 513
          ],
          columns: [
            20, 180, 223, 295, 369, 441, 513
          ]
        },
        moneyPayments: {
          rows: [
            546, 559, 573, 586, 600, 615, 628, 642, 655, 668, 683
          ],
          columns: [
            20, 180, 223, 295, 369, 441, 513
          ]
        }
      }
    }
  },

  methods: {

    async getBIRForm2307ByURL () {
      return await fetch(this.PDF_BIR_2307.urls.bir).then(
        file => file.arrayBuffer()
      )
    },

    async getBIRSignatureByURL () {
      return await fetch(this.PDF_BIR_2307.urls.signature).then(
        file => file.arrayBuffer()
      )
    },

    async previewBIRForm2307 (options = {
      company: {
        name: null,
        address: null,
        zip_code: null,
        tin: null
      },
      payee: {
        name: null,
        address: null,
        foreign_address: null,
        zip_code: null,
        tin: null
      },
      requester: {
        name: null,
        tin: null,
        position: null
      },
      date: {
        from: null,
        to: null
      },
      incomePayments: [],
      moneyPayments: []
    }) {
      // bir link: https://www.bir.gov.ph/images/bir_files/taxpayers_service_programs_and_monitoring_1/2307%20Jan%202018%20ENCS%20v3.pdf

      return new Promise((resolve, reject) => {
        return this.getBIRSignatureByURL().then(
          async signature => {
            return await this.getBIRForm2307ByURL().then(async pdfBIR => {
              return await PDFDocument.load(pdfBIR).then(
                async BIRPages => {
                  this.PDF_BIR_2307.font = await BIRPages.embedFont(StandardFonts.TimesRoman)

                  return await this.pdfLib({
                    title: `BIR FORM NO. 2307 for ${options.payee.name}`,
                    author: process.env.VUE_APP_TITLE,
                    creator: process.env.VUE_APP_TITLE,
                    subject: options.payee.name
                  }).then(
                    async pdfDocument => {
                      await pdfDocument.copyPages(BIRPages, [0]).then(
                        async copiedPages => {
                          const page = copiedPages[0]
                          page.setSize(
                            this.PDF_BIR_2307.width,
                            this.PDF_BIR_2307.height
                          )

                          // PAYEE
                          this.BIRForm2307Payee(page, {
                            name: options.payee.name,
                            registeredAddress: options.payee.address,
                            zipCode: options.payee.zip_code,
                            foreignAddress: null
                          })
                          this.BIRForm2307TIN(page, options.payee.tin, 150)

                          // COMPANY
                          this.BIRForm2307Company(page, {
                            name: options.company.name,
                            registeredAddress: options.company.address,
                            zipCode: options.company.zip_code
                          })
                          this.BIRForm2307TIN(page, options.company.tin, 265)

                          const totalOfIncomePayments = {
                            firstQuarter: 0,
                            secondQuarter: 0,
                            thirdQuarter: 0,
                            total: 0,
                            taxWithHeld: 0
                          }

                          options.incomePayments.forEach((rowIncomePayment, indexIncomePayment) => {
                            totalOfIncomePayments.taxWithHeld = parseFloat(
                              totalOfIncomePayments.taxWithHeld + rowIncomePayment.tax_withheld
                            ).toFixed(2)

                            totalOfIncomePayments.total = parseFloat(
                              totalOfIncomePayments.total + rowIncomePayment.amount_due
                            ).toFixed(2)

                            this.BIRForm2307FromDate(page, this.PDF_BIR_2307.dates[rowIncomePayment.quarter].from(rowIncomePayment?.date))
                            this.BIRForm2307ToDate(page, this.PDF_BIR_2307.dates[rowIncomePayment.quarter].to(rowIncomePayment?.date))

                            switch (rowIncomePayment.quarter) {
                              case '2nd-Q': {
                                totalOfIncomePayments.secondQuarter = parseFloat(
                                  totalOfIncomePayments.secondQuarter + rowIncomePayment.amount_due
                                ).toFixed(2)

                                this.BIRForm2307IncomePayments(
                                  page, {
                                    taxDescription: rowIncomePayment.tax_description,
                                    taxCode: rowIncomePayment.tax_code,
                                    firstQuarter: '',
                                    secondQuarter: '',
                                    thirdQuarter: '',
                                    total: this.numberFormatter(rowIncomePayment.amount_due),
                                    taxWithHeld: this.numberFormatter(rowIncomePayment.tax_withheld),
                                    rowPosition: this.PDF_BIR_2307.incomePayments.rows[indexIncomePayment]
                                  }
                                )
                                break
                              }
                              case '3rd-Q': {
                                totalOfIncomePayments.thirdQuarter = parseFloat(
                                  totalOfIncomePayments.thirdQuarter + rowIncomePayment.amount_due
                                ).toFixed(2)

                                this.BIRForm2307IncomePayments(
                                  page, {
                                    taxDescription: rowIncomePayment.tax_description,
                                    taxCode: rowIncomePayment.tax_code,
                                    firstQuarter: '',
                                    secondQuarter: '',
                                    thirdQuarter: '',
                                    total: this.numberFormatter(rowIncomePayment.amount_due),
                                    taxWithHeld: this.numberFormatter(rowIncomePayment.tax_withheld),
                                    rowPosition: this.PDF_BIR_2307.incomePayments.rows[indexIncomePayment]
                                  }
                                )
                                break
                              }
                              default: {
                                totalOfIncomePayments.firstQuarter = parseFloat(
                                  totalOfIncomePayments.firstQuarter + rowIncomePayment.amount_due
                                ).toFixed(2)

                                this.BIRForm2307IncomePayments(
                                  page, {
                                    taxDescription: rowIncomePayment.tax_description,
                                    taxCode: rowIncomePayment.tax_code,
                                    firstQuarter: '',
                                    secondQuarter: '',
                                    thirdQuarter: '',
                                    total: this.numberFormatter(rowIncomePayment.amount_due),
                                    taxWithHeld: this.numberFormatter(rowIncomePayment.tax_withheld),
                                    rowPosition: this.PDF_BIR_2307.incomePayments.rows[indexIncomePayment]
                                  }
                                )
                                break
                              }
                            }
                          })

                          this.BIRForm2307IncomePayments(
                            page, {
                              taxDescription: '',
                              taxCode: '',
                              firstQuarter: '',
                              secondQuarter: '',
                              thirdQuarter: '',
                              total: String(totalOfIncomePayments.total > 0 ? this.numberFormatter(totalOfIncomePayments.total) : ''),
                              taxWithHeld: String(totalOfIncomePayments.taxWithHeld > 0 ? this.numberFormatter(totalOfIncomePayments.taxWithHeld) : ''),
                              rowPosition: this.PDF_BIR_2307.incomePayments.rows[10]
                            }
                          )

                          const totalOfMoneyPayments = {
                            firstQuarter: 0,
                            secondQuarter: 0,
                            thirdQuarter: 0,
                            total: 0,
                            taxWithHeld: 0
                          }

                          options.moneyPayments.forEach((rowMoneyPayment, indexMoneyPayment) => {
                            this.BIRForm2307FromDate(page, this.PDF_BIR_2307.dates[rowMoneyPayment.quarter].from(rowMoneyPayment?.date))
                            this.BIRForm2307ToDate(page, this.PDF_BIR_2307.dates[rowMoneyPayment.quarter].to(rowMoneyPayment?.date))

                            switch (rowMoneyPayment.quarter) {
                              case '2nd-Q': {
                                totalOfMoneyPayments.secondQuarter = parseFloat(
                                  totalOfMoneyPayments.secondQuarter + rowMoneyPayment.amount_due
                                ).toFixed(2)

                                this.BIRForm2307MoneyPayments(
                                  page, {
                                    taxDescription: rowMoneyPayment.tax_description,
                                    taxCode: rowMoneyPayment.tax_code,
                                    firstQuarter: '',
                                    secondQuarter: '', // this.numberFormatter(rowMoneyPayment.amount_due),
                                    thirdQuarter: '',
                                    total: this.numberFormatter(rowMoneyPayment.amount_due),
                                    taxWithHeld: this.numberFormatter(rowMoneyPayment.tax_withheld),
                                    rowPosition: this.PDF_BIR_2307.moneyPayments.rows[indexMoneyPayment]
                                  }
                                )
                                break
                              }
                              case '3rd-Q': {
                                totalOfMoneyPayments.thirdQuarter = parseFloat(
                                  totalOfMoneyPayments.thirdQuarter + rowMoneyPayment.amount_due
                                ).toFixed(2)

                                this.BIRForm2307MoneyPayments(
                                  page, {
                                    taxDescription: rowMoneyPayment.tax_description,
                                    taxCode: rowMoneyPayment.tax_code,
                                    firstQuarter: '',
                                    secondQuarter: '',
                                    thirdQuarter: '', // this.numberFormatter(rowMoneyPayment.amount_due),
                                    total: this.numberFormatter(rowMoneyPayment.amount_due),
                                    taxWithHeld: this.numberFormatter(rowMoneyPayment.tax_withheld),
                                    rowPosition: this.PDF_BIR_2307.moneyPayments.rows[indexMoneyPayment]
                                  }
                                )
                                break
                              }
                              default: {
                                totalOfMoneyPayments.firstQuarter = parseFloat(
                                  totalOfMoneyPayments.firstQuarter + rowMoneyPayment.amount_due
                                ).toFixed(2)

                                this.BIRForm2307MoneyPayments(
                                  page, {
                                    taxDescription: rowMoneyPayment.tax_description,
                                    taxCode: rowMoneyPayment.tax_code,
                                    firstQuarter: '', // this.numberFormatter(rowMoneyPayment.amount_due),
                                    secondQuarter: '',
                                    thirdQuarter: '',
                                    total: this.numberFormatter(rowMoneyPayment.amount_due),
                                    taxWithHeld: this.numberFormatter(rowMoneyPayment.tax_withheld),
                                    rowPosition: this.PDF_BIR_2307.moneyPayments.rows[indexMoneyPayment]
                                  }
                                )
                                break
                              }
                            }
                          })

                          this.BIRForm2307MoneyPayments(
                            page, {
                              taxDescription: '',
                              taxCode: '',
                              firstQuarter: '', // String(totalOfMoneyPayments.firstQuarter > 0 ? this.numberFormatter(totalOfMoneyPayments.firstQuarter) : ''),
                              secondQuarter: '', // String(totalOfMoneyPayments.secondQuarter > 0 ? this.numberFormatter(totalOfMoneyPayments.secondQuarter) : ''),
                              thirdQuarter: '', // String(totalOfMoneyPayments.thirdQuarter > 0 ? this.numberFormatter(totalOfMoneyPayments.thirdQuarter) : ''),
                              total: String(totalOfMoneyPayments.total > 0 ? this.numberFormatter(totalOfMoneyPayments.total) : ''),
                              taxWithHeld: String(totalOfMoneyPayments.taxWithHeld > 0 ? this.numberFormatter(totalOfMoneyPayments.taxWithHeld) : ''),
                              rowPosition: this.PDF_BIR_2307.moneyPayments.rows[10]
                            }
                          )

                          this.BIRForm2307User(page, {
                            name: options.requester.name,
                            tin: options.requester.tin,
                            position: options.requester.position
                          })

                          this.BIRForm2307Conforme(page, options.payee.name)

                          const embededImage = await pdfDocument.embedPng(signature).catch(
                            () => reject(new Error('Unable to load Authorized Representative - PNG Signature'))
                          )
                          page.drawImage(embededImage, {
                            x: (page.getWidth() / 2) - (120 / 2),
                            y: Number(165),
                            width: 136,
                            height: 125
                          })

                          pdfDocument.addPage(page)
                          pdfDocument.save()
                        }
                      )
                      return pdfDocument
                    }
                  ).then(resolve)
                }
              ).catch(
                () => reject(new Error('Unable to load BIR FORM 2307 - PDF File'))
              )
            })
          }
        )
      })
    },

    async BIRForm2307FromDate (page, date) {
      const options = {
        x: this.PDF_BIR_2307.width - 456,
        y: this.PDF_BIR_2307.height - 119,
        size: this.PDF_BIR_2307.fontSize
      }
      // FROM MONTH
      page.drawText(date[0], options)
      page.drawText(date[1], Object.assign(options, { x: this.PDF_BIR_2307.width - 443 }))
      // FROM DAY
      page.drawText(date[2], Object.assign(options, { x: this.PDF_BIR_2307.width - 430 }))
      page.drawText(date[3], Object.assign(options, { x: this.PDF_BIR_2307.width - 416 }))
      // FROM YEAR
      page.drawText(date[4], Object.assign(options, { x: this.PDF_BIR_2307.width - 404 }))
      page.drawText(date[5], Object.assign(options, { x: this.PDF_BIR_2307.width - 391 }))
      page.drawText(date[6], Object.assign(options, { x: this.PDF_BIR_2307.width - 377 }))
      page.drawText(date[7], Object.assign(options, { x: this.PDF_BIR_2307.width - 364 }))
    },

    async BIRForm2307ToDate (page, date) {
      const options = {
        x: this.PDF_BIR_2307.width - 208,
        y: this.PDF_BIR_2307.height - 119,
        size: this.PDF_BIR_2307.fontSize
      }
      // TO MONTH
      page.drawText(date[0], options)
      page.drawText(date[1], Object.assign(options, { x: this.PDF_BIR_2307.width - 195 }))
      // TO DAY
      page.drawText(date[2], Object.assign(options, { x: this.PDF_BIR_2307.width - 182 }))
      page.drawText(date[3], Object.assign(options, { x: this.PDF_BIR_2307.width - 168 }))
      // TO YEAR
      page.drawText(date[4], Object.assign(options, { x: this.PDF_BIR_2307.width - 156 }))
      page.drawText(date[5], Object.assign(options, { x: this.PDF_BIR_2307.width - 143 }))
      page.drawText(date[6], Object.assign(options, { x: this.PDF_BIR_2307.width - 129 }))
      page.drawText(date[7], Object.assign(options, { x: this.PDF_BIR_2307.width - 116 }))
    },

    async BIRForm2307TIN (page, tin = '000-000-000-0000X', rowPosition = 150) { // 150, 265
      const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
      if (tin) {
        const values = tin.split('-')
        const options = {
          x: this.PDF_BIR_2307.width - 402,
          y: this.PDF_BIR_2307.height - rowPosition,
          size: this.PDF_BIR_2307.fontSize
        }
        page.drawText(values[0][0], options)
        page.drawText(values[0][1], Object.assign(options, { x: this.PDF_BIR_2307.width - 390 }))
        page.drawText(values[0][2], Object.assign(options, { x: this.PDF_BIR_2307.width - 375 }))

        page.drawText(values[1][0], Object.assign(options, { x: this.PDF_BIR_2307.width - 350 }))
        page.drawText(values[1][1], Object.assign(options, { x: this.PDF_BIR_2307.width - 337 }))
        page.drawText(values[1][2], Object.assign(options, { x: this.PDF_BIR_2307.width - 324 }))

        page.drawText(values[2][0], Object.assign(options, { x: this.PDF_BIR_2307.width - 299 }))
        page.drawText(values[2][1], Object.assign(options, { x: this.PDF_BIR_2307.width - 285 }))
        page.drawText(values[2][2], Object.assign(options, { x: this.PDF_BIR_2307.width - 272 }))

        page.drawText(values[3][0], Object.assign(options, { x: this.PDF_BIR_2307.width - 247 }))
        page.drawText(values[3][1], Object.assign(options, { x: this.PDF_BIR_2307.width - 231 }))
        page.drawText(values[3][2], Object.assign(options, { x: this.PDF_BIR_2307.width - 216 }))
        page.drawText(
          numbers.includes(Number(values[3][3])) ? values[3][3] : '0',
          Object.assign(options, { x: this.PDF_BIR_2307.width - 202 })
        )
        page.drawText(
          numbers.includes(Number(values[3][4])) ? values[3][4] : '0',
          Object.assign(options, { x: this.PDF_BIR_2307.width - 187 })
        )
      }
    },

    async BIRForm2307Payee (page, config) {
      const options = {
        x: 40,
        y: this.PDF_BIR_2307.height - 176,
        size: this.PDF_BIR_2307.fontSize
      }
      // Payee
      page.drawText(config.name, options)
      page.drawText(config.registeredAddress, Object.assign(options, { y: this.PDF_BIR_2307.height - 205 }))

      if (config?.zipCode) {
        Object.assign(options, { y: this.PDF_BIR_2307.height - 205 })
        page.drawText(config?.zipCode[0], Object.assign(options, { x: this.PDF_BIR_2307.width - 67 }))
        page.drawText(config?.zipCode[1], Object.assign(options, { x: this.PDF_BIR_2307.width - 54 }))
        page.drawText(config?.zipCode[2], Object.assign(options, { x: this.PDF_BIR_2307.width - 42 }))
        page.drawText(config?.zipCode[3], Object.assign(options, { x: this.PDF_BIR_2307.width - 28 }))
      }

      if (config?.foreignAddress) {
        page.drawText(config?.foreignAddress, Object.assign(options, { y: this.PDF_BIR_2307.height - 233 }))
      }
    },

    async BIRForm2307Company (page, config) {
      const options = {
        x: 40,
        y: this.PDF_BIR_2307.height - 291,
        size: this.PDF_BIR_2307.fontSize
      }
      // Company
      page.drawText(config.name, options)
      page.drawText(config.registeredAddress, Object.assign(options, { y: this.PDF_BIR_2307.height - 320 }))

      if (config?.zipCode) {
        Object.assign(options, { y: this.PDF_BIR_2307.height - 320 })
        page.drawText(config.zipCode[0], Object.assign(options, { x: this.PDF_BIR_2307.width - 67 }))
        page.drawText(config.zipCode[1], Object.assign(options, { x: this.PDF_BIR_2307.width - 54 }))
        page.drawText(config.zipCode[2], Object.assign(options, { x: this.PDF_BIR_2307.width - 42 }))
        page.drawText(config.zipCode[3], Object.assign(options, { x: this.PDF_BIR_2307.width - 28 }))
      }
    },

    async BIRForm2307IncomePayments (page, config) {
      const options = {
        x: this.PDF_BIR_2307.incomePayments.columns[0],
        y: this.PDF_BIR_2307.height - config.rowPosition,
        size: this.PDF_BIR_2307.fontSize
      }
      page.drawText(config.taxDescription, options)
      page.drawText(config.taxCode, Object.assign(options, { x: this.PDF_BIR_2307.incomePayments.columns[1] }))
      page.drawText(config.firstQuarter, Object.assign(options, { x: this.PDF_BIR_2307.incomePayments.columns[2] }))
      page.drawText(config.secondQuarter, Object.assign(options, { x: this.PDF_BIR_2307.incomePayments.columns[3] }))
      page.drawText(config.thirdQuarter, Object.assign(options, { x: this.PDF_BIR_2307.incomePayments.columns[4] }))
      page.drawText(config.total, Object.assign(options, { x: this.PDF_BIR_2307.incomePayments.columns[5] }))
      page.drawText(config.taxWithHeld, Object.assign(options, { x: this.PDF_BIR_2307.incomePayments.columns[6] }))
    },

    async BIRForm2307MoneyPayments (page, config) {
      const options = {
        x: this.PDF_BIR_2307.moneyPayments.columns[0],
        y: this.PDF_BIR_2307.height - config.rowPosition,
        size: this.PDF_BIR_2307.fontSize
      }
      page.drawText(config.taxDescription, options)
      page.drawText(config.taxCode, Object.assign(options, { x: this.PDF_BIR_2307.moneyPayments.columns[1] }))
      page.drawText(config.firstQuarter, Object.assign(options, { x: this.PDF_BIR_2307.moneyPayments.columns[2] }))
      page.drawText(config.secondQuarter, Object.assign(options, { x: this.PDF_BIR_2307.moneyPayments.columns[3] }))
      page.drawText(config.thirdQuarter, Object.assign(options, { x: this.PDF_BIR_2307.moneyPayments.columns[4] }))
      page.drawText(config.total, Object.assign(options, { x: this.PDF_BIR_2307.moneyPayments.columns[5] }))
      page.drawText(config.taxWithHeld, Object.assign(options, { x: this.PDF_BIR_2307.moneyPayments.columns[6] }))
    },

    async BIRForm2307User (page, config) {
      const positionAndTin = `${config.position} / ${config.tin}`
      const pageWidthInHalf = page.getWidth() / 2
      page.drawText(config.name, {
        x: (pageWidthInHalf - (this.PDF_BIR_2307.font.widthOfTextAtSize(config.name, this.PDF_BIR_2307.fontSize) / 2)),
        y: 200,
        size: this.PDF_BIR_2307.fontSize
      })
      page.drawText(positionAndTin, {
        x: (pageWidthInHalf - (this.PDF_BIR_2307.font.widthOfTextAtSize(positionAndTin, this.PDF_BIR_2307.fontSize) / 2)),
        y: 190,
        size: this.PDF_BIR_2307.fontSize
      })
    },

    async BIRForm2307Conforme (page, name) {
      page.drawText(name, {
        x: ((page.getWidth() / 2) - (this.PDF_BIR_2307.font.widthOfTextAtSize(name, this.PDF_BIR_2307.fontSize) / 2)),
        y: 118,
        size: this.PDF_BIR_2307.fontSize
      })
    }

  }
}
